.project-icon {
    height: 100px;
    border-radius: 10px;
}

.project-container {
    display: flex;
}

.description-container {
    margin: 20px 0px 20px 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
}

.description-container > h3 {
    margin: 2px;
}

.description-container > p {
    margin: 14px 0px;
    font-size: 1.0rem;
}

.icon-container{
    margin: auto 0;
}

.project-link > a {
    text-decoration: none;
    color: #e4e4e4;
    font-weight: 600;
    padding: 5px;
    border: 1px solid  #e4e4e4;
    border-radius: 5px;
}

.project-link > a:hover {
    color: #0f65a3;
}