html, body {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.1rem;
    background-color: #1B1B1B;
    color: #e4e4e4;
    margin: 0;
}

a {
    color: #ffffff;
}

a:hover {
    color: #e4e4e4;
}

.bmo-broken {
    height: 200px;

}