.input-group {
    display: flex;
    flex-direction: column;
    margin: 30px 0px;
}

.message-field {
    height: 100px;
    border-radius: 5px;
}
.email-field {
    height: 30px;
    border-radius: 5px;
}

.submit-btn {
    width: 100%;
    min-height: 40px;
    font-size: 1.2rem;
    font-weight: 600;
    background-color: #1B1B1B;
    border: 3px solid #e4e4e4;
    border-radius: 5px;
    color: #e4e4e4;
    cursor: pointer;
}
.submit-btn:hover {
    background-color: #161616;
}