.navbar {
    display: flex;
    background-color: #161616;
    height: 90px;
    justify-content: center;
    font-weight: 600;
}

.navitem {
    text-decoration: none;
    margin: auto 20px;
    color: #e4e4e4;
    cursor: pointer;
}

.ref-icon {
    height: 25px;
    position: relative;
    top: 5px;
    margin-right: 5px;
}