.avatar {
    height: 150px;
}

.page-content {
    max-width: 800px;
    margin: 50px auto;
}

.divider {
    background-color: #e4e4e4;
    opacity: 0.8;
    border-radius: 1px;
    margin: 50px auto;
}

.social>a {
    text-decoration: none;
    color: #e4e4e4;
    margin: auto 10px;
}

@media screen and (max-width: 992px) {
    .page-content {
        padding: 0 30px;
    }
}